<template>
  <div id="payment-add-section" class="flex-grow-1 d-flex flex-column gap-2 h-100">
    <div class="d-flex gap-2 pt-0 h-100 pb-1 border-bottom-primary" v-for="(payment, i) in payments" :key="payment.index" >
      <div class="flex-grow-1 d-flex flex-column flex-lg-row gap-2">
        <div class="flex-even d-flex flex-wrap gap-2 flex-grow-1">
          <!-- is paid -->
          <q-toggle :label="payment.paid?$t('supply-chain.purchase.paid'):$t('supply-chain.purchase.crediting')" v-model="payment.paid" color="success" class="flex-even flex-size-4" @input="payment.account=null; payment.drcr=null"/>
 
          <!-- Paid at -->
          <q-input filled dense hide-bottom-space v-model="payment.paidAt" mask="date" class="mb-0 flex-even flex-size-4">
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                  <q-date v-model="payment.paidAt" style="height:376px">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>

          <!-- Select finance account / type -->
          <q-select dense filled hide-bottom-space emit-value popup-content-class="mt-1" :label="capitalize($t('finance.account'))" v-model="payment.account" @input="updateItemAccount(i)"
            :options="payment.paid?cashOptions:nonCashOptions" option-value="_id" option-label="name" map-options class="flex-even m-0 flex-size-4"
            :rules="[ val=>val !== null && val !== undefined && val !== '' || $t('rule.required')]"  >
            <template #prepend><fa-icon :icon="['fad','sack-dollar']" fixed-width class="text-danger"/></template>
          </q-select>
          
          <!-- Amount -->
          <q-input type="number" dense filled hide-bottom-space clearable v-model="payment.amount" class="mb-0 rounded-lg flex-even flex-size-4" :label="capitalize($t('form.amount'))" :loading="loading" autocomplete="off"
            :rules="[val=>Boolean(val)||$t('rule.required')]" step="0.01" @change = calculateTotalPrice(i)>
            <template #prepend><small class="text-bold text-primary mr-25">{{$global.default_currency}}</small></template>
          </q-input>
        </div>
      </div>
      <!-- Remove Button -->
      <div class="d-flex flex-column justify-content-around">
        <q-btn push color="primary" class="ml-50 shadow-sm" @click="copyItem(i)"><fa-icon :icon="['fas','copy']" size="1x" /></q-btn>
        <q-btn push color="danger" class="ml-50 shadow-sm" @click="removeItem(i)"><fa-icon :icon="['fas','trash-alt']" size="1x" /></q-btn>
      </div>
    </div>

    <q-btn dense rounded @click.stop="addItem" color="primary" class="shadow-sm">
      <fa-icon icon="plus" class="mr-50"/><span class="text-capitalize">{{$t('action.add')}}</span>
    </q-btn>
  </div>
</template>

<script>
import axios from '@axios'
import { ref, watch } from '@vue/composition-api'
import { BButton } from 'bootstrap-vue'
import { toNumber } from '@core/utils/filter'

import $global from '@/globalVariables'

import FlatPickr from 'vue-flatpickr-component'
import { capitalize } from '@core/utils/filter'

// i18n
import { useUtils as useI18nUtils } from '@core/libs/i18n'
const { t } = useI18nUtils()

export default {
  name: 'payment-add-section',
  components: { BButton, FlatPickr },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    purchasePayments: {
      type: Array,
      default: ()=>[]
    },
    isReturn: {
      type: Boolean,
      default: false
    }
  },
  setup(props,{emit}){
    // i18n
    const tt = {
      atLeastOne: t('rule.at_least_one')
    }

    let currentIndex = 1
    const emptyPayment = {
      isPayment: true, paid: true,
      account:'', currency:$global.default_currency, quantity:1, amount:0, 
      paidAt: (new Date).toLocaleDateString(undefined,{year: "numeric", month: "2-digit", day: "2-digit"})
    }
    const payments = ref([])
    payments.value.push({...JSON.parse(JSON.stringify(emptyPayment)),index:currentIndex})

// Load options
    const cashOptions = ref([])
    const nonCashOptions = ref([])
    axios.get(`/account/list/payment`).then(response => {
      if (response.data.cash && response.data.noncash) {
        cashOptions.value = response.data.cash
        nonCashOptions.value = response.data.noncash
      }
    }).catch(e=>console.log(e))

// 确认amount符号
    const calculateTotalPrice = (i) => {
      let sign = 1
      if(props.isReturn) sign *= (-1)
      if (payments.value[i].paid) sign *= (-1)
      payments.value[i].amount = toNumber(Math.abs(payments.value[i].amount) * sign)
    }

// items
    const addItem = () => {
      currentIndex++;
      payments.value.push({...JSON.parse(JSON.stringify(emptyPayment)),index:currentIndex})
    }

    const copyItem = function(i) {
      currentIndex++;
      const newItem = JSON.parse(JSON.stringify(payments.value[i]))
      delete newItem._id
      payments.value.push({...newItem, index:currentIndex})
    }

    const removeItem = (i) => {
      //if(payments.value.length<=1) return Toast.fire({icon:'error', title: tt.atLeastOne})
      payments.value.splice(i, 1)
    }

    // 选择account后更新drcr
    const updateItemAccount = (i) =>{
      cashOptions.value.concat(nonCashOptions.value).forEach(option => {
        if(option._id === payments.value[i].account) {
          payments.value[i].drcr = option.drcr
        }
      })
      calculateTotalPrice(i)
    }

    // Emit [items]
    watch(payments, ()=>{
      emit('update:purchasePayments', payments.value)
    }, {deep: true, immediate: true})

    return {
      capitalize,
      cashOptions, nonCashOptions,
      payments,

      addItem,
      copyItem,
      removeItem,
      updateItemAccount,
      calculateTotalPrice
    }
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>