<template>
  <component :is="isDialog?'q-dialog':'div'" id="supplier-list" v-model="isDialogOpen" @hide="cancel">
    <div :class="{'dialog-view':isDialog}">
      <ListTable ref="tableRef" slim :columns="columns" :fetchAsync="fetchSuppliers" dataName="suppliers" :selectable="selectable" :showIcon="showIcon" :icon="['fad', 'people-carry']" search="name,phone,email,tags">
        <!-- Add button -->
        <template #add>
          <q-btn v-if="$can('create','Supplier')" push no-caps color="primary" @click.stop="supplierEditRef.open().then(v=>v?tableRef.refetchTable():'')">
            <span class="text-nowrap text-capitalize">{{$t('action.add')}}</span>
          </q-btn>
        </template>
        <!-- Company name -->
        <template #cell(corpName)="{ item }">
          <component :is="isDialog?'div':'q-btn'" flat padding="0.25rem 0.50rem" @click="()=>isDialog?'':supplierViewRef.open(item._id).then(v=>v?tableRef.refetchTable():'')" class="w-100 rounded-lg">
            <div class="flex-grow-1 d-flex justify-content-between align-items-center">
              <div class="flex gap-2 align-items-center flex-nowrap">
                <Avatar :url="item.files" defaultIcon="building"/>
                <span class="h5 m-0">
                  <div>{{ item.corpName }}</div>
                  <div v-if="isDialog">{{ item.sku }}</div>
                </span>
              </div>
              <fa-icon v-if="!isDialog" :icon="['far','chevron-right']" class="text-primary ml-1 mr-50"/>
            </div>
          </component>
        </template>

        <!-- Name -->
        <template #cell(name)="data">
          {{data.value}}
        </template>

        <!-- Tags -->
        <template #cell(tags)="{item}">
          <div class="d-flex flex-wrap gap-1">
            <q-chip dense v-for="(tag, index) in item.tags" color="light-primary" :key="tag.index">{{tag}}</q-chip>
          </div>
        </template>

        <!-- Actions -->
        <template #cell(actions)="{item}">
          <q-btn v-if="$can('update','Supplier')" flat @click.stop="supplierEditRef.open(item._id).then(v=>v?tableRef.refetchTable():'')"><feather-icon icon="EditIcon" class="text-primary cursor-pointer" size="16"/></q-btn>
          <q-btn v-if="$can('delete','Supplier')" flat @click.stop="deleteSupplier(item._id).then(v=>v?tableRef.refetchTable():'')"><feather-icon  icon="TrashIcon" class="text-danger" size="16"/></q-btn>
        </template>
      </ListTable>

      <SupplierView ref="supplierViewRef"/>
      <SupplierEdit ref="supplierEditRef"/>
    </div>
  </component>
</template>


<script>
import { ref, watch } from '@vue/composition-api'
import { capitalize, i18n } from '@core/utils/filter'
import store from '@/store'
import useSupplier from './useSupplier'
import SupplierEdit from './SupplierEdit'
import SupplierView from './SupplierView'
import Avatar from '@core/components/Avatar'
import ListTable from '@core/components/ListTable'

export default {
  name: 'supplier-list',
  components: {
    Avatar, ListTable,
    SupplierEdit, 
    SupplierView    
  },
  props:{
    isDialog: Boolean,
    showIcon: Boolean,
    selectable: Boolean,
    filters: {
      type: Object,
      default: ()=>{}
    },
    sort: String
  },
  filters: {
    capitalize
  },  
  setup() {
    // Ref
    const supplierViewRef = ref(null)
    const supplierEditRef = ref(null)
    const { deleteSupplier } = useSupplier()

    // Table
    const tableRef = ref(null)
    const columns = ref([
      { key: 'corpName', label:i18n('form.corp_name'), sortable: true },
      { key: 'name', label:i18n('form.name'), sortable: true },
      { key: 'phone', label:i18n('form.phone'), sortable: true },
      { key: 'email', label:i18n('form.email'), sortable: true},
      { key: 'tags', label:i18n('form.tags'), sortable: true },
      { key: 'actions', label:i18n('form.actions'), noFilter: true },
    ])

    // Action
    const fetchSuppliers = params => store.dispatch('supplier/fetchSuppliers', params)

    // Dialog view
    const isDialogOpen = ref(false)
    
    let cancelAction = () => isDialogOpen.value = false
    const cancel = ()=>cancelAction()
    const selectOne = ()=>new Promise(res => {
      // 隐藏column
      columns.value = columns.value.filter(c=>c.key!=='actions')
      isDialogOpen.value = true
      cancelAction = ()=>{
        isDialogOpen.value = false
        res(false)
      }
      watch(tableRef, ref=>ref?.clickRow().then(val=>{
        isDialogOpen.value = false
        res(val)
      }))
    })

    return {
      // Dialog View
      isDialogOpen,
      cancel,
      selectOne,
      
      // Table
      columns,
      tableRef,

      // Action
      fetchSuppliers,
      deleteSupplier,

      // Refs
      supplierEditRef,
      supplierViewRef
    }
  }
}
</script>

<style lang="scss">
#supplier-list {
  .dialog-view {
    max-width: 900px;
    width:100%;
  }
}
</style>