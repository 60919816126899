<template>
  <component :is="isPage?'div':'q-dialog'" id="purchase-edit" v-model="isDialogOpen" @hidden="cancel" :persistent="persistent">
    <div class="card m-0 h-100 shadow-sm overflow-hidden" :class="{'dialog-view':!isPage}">
      <div v-if="!isPage" class="bg-light-secondary p-2 d-flex align-items-center flex-wrap gap-2 shadow-sm" style="z-index:10">
        <fa-icon :icon="purchaseData._id?'pen':'plus'" :class="purchaseData._id?'text-primary':'text-success'"/>
        <h4 class="m-0">{{$t(`supply-chain.purchase.${purchaseData._id?'edit':'add'}`)}}</h4>
        <q-space />
        <q-btn flat padding="5px" color="primary" :to="{name: purchaseData._id?'purchase-edit':'purchase-add', params:{purchaseId: purchaseData._id}}">
          <fa-icon icon="expand"/>
        </q-btn>
      </div>

      <q-form @submit="clickSubmit" class="h-100 d-flex flex-column">
        <component :is="isPage?'div':'q-scroll-area'" ref="scrollAreaRef" class="h-100" delay='600'
          :thumb-style="{ right: '4px', borderRadius: '5px', backgroundColor: '#7367f0', width: '5px', opacity: 0.75 }" 
          :bar-style="{ right: '2px', borderRadius: '9px', backgroundColor: '#7367f0', width: '9px', opacity: 0.2 }">
          <div>
            <!-- Details -->
            <div class="flex p-2 gap-2 flex-column flex-sm-row">
              <!-- Arrived at -->
              <q-input outlined dense hide-bottom-space v-model="purchaseData.orderedAt" mask="date" :rules="[val=>Boolean(val)||$t('rule.required'),'date']" class="mb-0 flex-even flex-size-4">
                <template #prepend><fa-icon :icon="['fad','calendar-minus']" fixed-width class="text-danger mr-1"/></template>
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                      <q-date v-model="purchaseData.orderedAt" style="height:376px">
                        <div class="row items-center justify-end">
                          <q-btn v-close-popup label="Close" color="primary" flat />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
              <!-- trackNo -->
              <q-input type="text" dense outlined hide-bottom-space clearable v-model="purchaseData.trackNo" class="mb-0 flex-even flex-size-4" 
                :label="$t('form.track_number')|capitalize" :loading="loading" autocomplete="off"
                :rules="[ val=>!val||val.length<=50 || $t('rule.too_long')]">
                <template #prepend><fa-icon :icon="['fad','satellite-dish']" fixed-width class="text-primary mr-25"/></template>
              </q-input>
              <!-- tags -->
              <q-select v-model="purchaseData.tags" outlined :label="$t('form.tags')|capitalize" new-value-mode="add-unique" :loading="loading" multiple hide-dropdown-icon use-input dense 
                :placeholder="$t('message.enter_add_new')" class="flex-even mb-0 flex-size-4">
                <template #selected-item="scope">
                  <q-chip dense removable class="mr-50" @remove="scope.removeAtIndex(scope.index)" :tabindex="scope.tabindex" color="light-primary" text-color="primary">{{scope.opt}}</q-chip>
                </template>
                <template #prepend><fa-icon :icon="['fad','tags']" fixed-width class="text-primary mr-25"/></template>
              </q-select>
            </div>

            <!-- Description -->
            <div>
              <h5 class="text-primary bg-light-secondary px-2 py-75 m-0 d-flex gap-2 align-items-center">
                <div>
                  <fa-icon :icon="['fad','align-left']" fixed-width class="mr-1"/>
                  {{$t('form.description')|capitalize}}
                </div>
              </h5>
              <q-input type="textarea" outlined dense hide-bottom-space v-model="purchaseData.description" rows="2" :label="$t('form.description')|capitalize" class="flex-even m-2 flex-size-6" 
                :rules="[ val=>!val || val.length<=500 || $t('rule.too_long')]">
                <template #prepend><fa-icon :icon="['fad','align-left']" fixed-width class="text-primary mr-25"/></template>
              </q-input>
            </div>
            

    <!-- 供应商 -->
            <h5 class="text-primary bg-light-secondary m-0 px-2 py-75 d-flex gap-2 align-items-center text-capitalize">
              <fa-icon :icon="['fad','building']" fixed-width class="my-50"/>{{$t('member.supplier._')}}
            </h5>
            <div class="d-flex px-2 py-1">
              <div v-if="purchaseData.supplier && purchaseData.supplier._id" @click.stop="selectSupplier" class="shadow-sm light-primary d-flex gap-2 border-primary rounded-lg px-1 py-50 bg-light-primary align-items-center">
                <Avatar :url="purchaseData.supplier.files" :defaultIcon="['fad', 'user']" />
                <div class="text-left">
                  <div class="h5 m-0">{{purchaseData.supplier.name}}</div>
                  <small>{{purchaseData.supplier.supplierNo}}</small>
                  <div>
                    <q-chip v-if="purchaseData.supplier.grade"  size="sm" :color="'light-'+purchaseData.supplier.grade.color" class="m-0">
                      <q-avatar :color="(purchaseData.supplier.mlmSeq===1||purchaseData.supplier.mlmSeq%2===0)?'primary':'danger'" text-color="white">{{purchaseData.supplier.level}}</q-avatar>
                      <span>{{purchaseData.supplier.grade.names | name}}</span>
                    </q-chip>
                  </div>
                </div>
              </div>
              <q-field v-else hide-bottom-space dense :value="purchaseData.member" borderless class="mb-0" :rules="[val=>Boolean(val) || $t('rule.required')]">
                <q-btn push no-caps @click.stop="selectSupplier" color="deep-orange">{{$t('member.choose_supplier')}}</q-btn>
              </q-field>
            </div>

    <!-- 项目 -->
            <h5 class="text-primary bg-light-secondary m-0 px-2 py-75 flex justify-content-between align-items-center">
              <span class="flex gap-2 align-items-center text-capitalize">
                <fa-icon :icon="['fad','list']" fixed-width class="my-50"/>{{$t('form.variants')}}
              </span>
              <span class="d-flex gap-2 align-items-center">
                <q-btn push round dense color="success" :loading="loading" @click.prevent.stop="addVariant"><fa-icon icon="plus"/></q-btn>
              </span>
            </h5>
            <div v-if="!purchaseData.variants || purchaseData.variants.length===0">
              <q-field hide-bottom-space :value="purchaseData.variants" borderless :rules="[val=>val.length>0]" class="mx-2 my-1 p-0 flex-grow-1">
                <div class="bg-light rounded-lg align-items-center flex-grow-1 text-center" @click.prevent.stop="addVariant">
                  <fa-icon :icon="['fad','cauldron']" size="5x" class="text-secondary m-2"/>
                </div>
              </q-field>
            </div>
            <div v-else class="px-2 overflow-auto">
              <div style="min-width:550px">
                <div class="d-flex gap-2 justify-content-between">
                  <span class="flex-size-3 h5 my-1 pl-2 text-primary text-capitalize">{{$t('form.name')}}</span>
                  <span class="flex-size-2 text-center h5 my-1 text-primary text-capitalize">{{$t('form.price')}}</span>
                  <span class="flex-size-2 text-center h5 my-1 text-primary text-capitalize">{{$t('form.quantity')}}</span>
                  <span class="flex-size-15 text-right h5 my-1 text-primary text-capitalize">{{$t('form.amount')}}</span>
                  <span class="flex-size-1"></span>
                </div>
                <draggable v-model="purchaseData.variants" handle=".drag-handle">
                  <transition-group name="items-transition">
                    <div v-for="variant in purchaseData.variants" :key="variant._id" class="d-flex gap-2 justify-content-between align-items-center border-top">
                      <!-- name -->
                      <div class="d-flex flex-size-3 align-items-center gap-2">
                        <fa-icon icon="align-justify" class="drag-handle text-primary" style="font-size:20px"/>
                        <Avatar :url="variant.files" class="d-none d-sm-table-cell"/>
                        <div class="d-flex flex-column">
                          <q-chip dense class="pl-0">
                            <q-chip dense :color="(variant.isInventory || variant.product && variant.product.isInventory)?'primary':'warning'" text-color="white" class="mr-50">
                              {{variant.productNames || (variant.product && variant.product.names) | name}}
                            </q-chip>
                            {{variant.names | name}}
                          </q-chip>
                          <small>{{variant.sku}}</small>
                        </div>
                      </div>
                      <!-- price -->
                      <q-input type="number" outlined rounded clearable hide-bottom-space :step="0.01" input-class="text-right" class="mb-0 flex-even flex-size-2" @input="calculateTotalPrice"
                        v-model.number="variant.price" :rules="[val=>val>=0 && val!==null]" :prefix="$t(`currency.${variant.currency}.sign`)" />
                      <!-- quantity -->
                      <q-input type="number" outlined hide-bottom-space rounded :step="1" :loading="loading" input-class="text-center" color="primary" class="my-50 flex-even flex-size-2"
                        v-model.number="variant.quantity" :rules="[val=>val>0]" @input="calculateTotalPrice">
                        <template #prepend><q-btn color="danger" push dense round icon="remove" @click.prevent.stop="variant.quantity = Math.max(--variant.quantity,1), calculateTotalPrice()" /></template>
                        <template #append><q-btn color="primary" push dense round icon="add" @click.prevent.stop="variant.quantity = Math.max(++variant.quantity,1), calculateTotalPrice()" /></template>
                      </q-input>
                      <div class="flex-even flex-size-15 h5 m-0 text-right text-nowrap w-30">{{variant.total | price($t(`currency.${variant.currency}.sign`)) }}</div>
                      <!-- Actions -->
                      <div class="flex-even flex-size-1 text-right">
                        <q-btn color="danger" flat :loading="loading" @click.prevent.stop="purchaseData.variants = purchaseData.variants.filter(v=>v._id!==variant._id)"><fa-icon :icon="['far','trash-alt']" style="font-size:20px"/></q-btn>
                      </div>
                    </div>
                  </transition-group>
                </draggable>
                <div class="d-flex gap-2 justify-content-between text-capitalize border-top">
                  <span class="flex-size-3"></span>
                  <span class="flex-size-2"></span>
                  <span class="flex-size-2 text-center text-capitalize h5 my-1">{{$t('form.tax_included')}}</span>
                  <span class="flex-size-15 text-right h5 my-1 text-primary">{{ purchaseData.variants.reduce((a,v)=>a+v.total,0) | toNumber | price($t(`currency.${purchaseData.currency}.sign`))}}</span>
                  <span class="flex-size-1"></span>
                </div>
                <div class="d-flex gap-2 justify-content-between text-capitalize border-top">
                  <span class="flex-size-3"></span>
                  <span class="flex-size-2"></span>
                  <span class="flex-size-2 text-center text-capitalize h5 my-1">{{$t('form.total')}}</span>
                  <span class="flex-size-15 text-right h5 my-1 text-primary">{{ purchaseData.variants.reduce((a,v)=>a+v.total,0) | toNumber | price($t(`currency.${purchaseData.currency}.sign`))}}</span>
                  <span class="flex-size-1"></span>
                </div>
              </div>
            </div>
            <!-- Image -->
            <h5 class="text-primary bg-light-secondary m-0 px-2 py-1 d-flex gap-2 align-items-center">
              <span class="flex gap-2 align-items-center text-capitalize">
                <fa-icon :icon="['fad','image']" fixed-width />
                {{$t('form.image')}}
              </span>
            </h5>
            <FileUpload size="160px" model="purchase" :modelId="purchaseData._id" :files.sync="purchaseData.files" limit="5" class="px-2 py-1"/>
          </div>
        </component>
        <div class="d-flex justify-content-between px-2 py-1 shadow-top bg-light-secondary" style="z-index:10">
          <q-btn push no-caps color="primary" class="mr-2" type="submit" :loading="loading" :label="capitalize($t('action.save'))"/>
          <q-btn push no-caps color="warning" :loading="loading" @click.prevent.stop="cancel" :label="capitalize($t('action.cancel'))"/>
        </div>
      </q-form>
      <SupplierList showIcon isDialog ref="supplierListRef" />
      <VariantList showIcon isDialog ref="variantListRef" />
      <q-inner-loading :showing="loading">
        <q-spinner-gears size="50px" color="primary" />
      </q-inner-loading>
    </div>
  </component>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import { ref, set }  from '@vue/composition-api'
import { price, capitalize, toNumber, i18n, name } from '@core/utils/filter'
import { Toast, Dialog } from '@core/utils/other'
import Avatar from '@core/components/Avatar'
import usePurchase from './usePurchase'
import FileUpload from '@core/components/file-upload/FileUpload'
// Dialog
import SupplierList from '../../member/supplier/SupplierList'
import VariantList from '../../supply-chain/variant/VariantList'

import draggable from 'vuedraggable'

export default {
  name: 'purchase-edit',
  components: {
    Avatar, FileUpload,
    SupplierList,
    VariantList,
    draggable
  },
  filters: {
    price, capitalize, name, toNumber
  },
  props:{
    isPage: Boolean,
    persistent: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    // ref
    const variantListRef = ref(null)

    const { route, router } = useRouter()
    const {
      total,
      loading,
      purchaseData,
      resetPurchase,
      fetchPurchase,
    } = usePurchase()    

    let submit = ()=>{
      loading.value = true
      store.dispatch(purchaseData.value._id?'purchase/updatePurchase':'purchase/addPurchase', purchaseData.value).then(() => {
        loading.value = false
        Toast.fire({icon: 'success', title: capitalize(i18n('form.success'))})
        router.go(-1)
      }).catch(e =>{
        loading.value = false
        Dialog(false).fire({ icon: 'error', title: e.message })
      })
    }
    const clickSubmit = () => submit()

    // Page view
    if (props.isPage) {
      resetPurchase()
      if (route.value.params.purchaseId) {
        loading.value = true
        fetchPurchase(route.value.params.purchaseId, ()=>{
          loading.value = false
          // if(purchaseData.value && purchaseData.value.variants && purchaseData.value.variants.length>0) purchaseData.value.variants.forEach(v => {
            
          // })
        })
      }
    }


    // Dialog view
    const isDialogOpen = ref(false)
    let cancelAction = () => router.go(-1)
    const cancel = ()=>cancelAction()
    const open = async id => {
      resetPurchase()
      isDialogOpen.value = true
      if(id) {
        loading.value = true
        await fetchPurchase(id, ()=>{
          loading.value = false
          // if(purchaseData.value && purchaseData.value.variants && purchaseData.value.items.length>0) purchaseData.value.items.forEach(i => i.uid = uuidv4())
        })
      }
      return new Promise(res=>{
        submit = () => {    
          loading.value = true
          store.dispatch(id?'pruchase/updatePurchase':'pruchase/addPurchase', purchaseData.value).then(() => {
            loading.value = false
            Toast.fire({icon: 'success', title: capitalize(i18n('form.success'))})
            isDialogOpen.value = false
            res(true)
          }).catch(e =>{
            loading.value = false
            Dialog(false).fire({ title: e.message })
          })
        }
        cancelAction = ()=>{
          isDialogOpen.value = false
          res(false)
        }
      })
    }

    // 选择供应商
    const supplierListRef  = ref(null)
    const selectSupplier = ()=> supplierListRef.value.selectOne().then(v=> {
      if (v!==false) set(purchaseData.value, 'supplier', v)
    })

    // Variants
    const addVariant = () => {
      variantListRef.value.selectOne().then(v=>{
        if(v===false) return
        const duplicated = purchaseData.value.variants && purchaseData.value.variants.find(variant=>variant._id === v._id)
        if (duplicated) return Toast.fire({icon:'error', title: i18n('message.duplicated')})
        purchaseData.value.variants.push({
          ...v,
          variant:v.variant||v._id,
          price: v.purchasePrice || null,
          quantity:1,
          total: v.purchasePrice || 0
        })
      })
    }
    
    const calculateTotalPrice = () => {
      purchaseData.value.variants.reduce((a,v)=>{
        if(v.price !== null) v.price = toNumber(v.price)
        v.total = toNumber((v.price||0) * v.quantity)
        return toNumber(a + v.total)
      }, 0)
    }
    
    return {
      total,
      loading, capitalize, toNumber,

      purchaseData,
      clickSubmit,

      // Dialog
      isDialogOpen,
      open,
      cancel,

      variantListRef,

      // 供应商
      supplierListRef,
      selectSupplier,

      // Actions
      calculateTotalPrice,
      fetchPurchase,

      // Items
      addVariant,

    }
  },
}
</script>

<style lang="scss">
#purchase-edit {
  .dialog-view {
    max-width: 800px;
    width:100%;
  }
  .q-textarea {
    .q-field__control{
      padding-right: 2px
    }
  }
  .phone {
    .q-field__control-container {
      flex-wrap: nowrap;
      width: 60px
    }
  }

  .items-transition-enter-active {
    transition: all .3s ease;
  }
  .items-transition-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .items-transition-enter, .items-transition-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }

  .w-30 {
    width: 30%
  }
}
</style>