<template>
  <q-dialog id="pay-dialog" v-model="isDialogOpen" persistent @hide="cancel">
    <div v-if="purchaseData._id && purchaseData" class="card m-0 w-100 h-100">
      <div class="bg-light-secondary px-2 py-1 d-flex align-items-center flex-wrap gap-2 shadow">
        <fa-icon icon="user-check" class="text-primary"/>
        {{purchaseData.orderNo?purchaseData.orderNo.toUpperCase():''}}
        <fa-icon v-if="purchaseData.isReturn" icon="undo" class="mx-25 text-danger"/>
      </div>

      <q-form @submit="payPurchase" class="h-100 d-flex flex-column">
        <q-scroll-area class="h-100" delay='600' 
          :thumb-style="{ right: '4px', borderRadius: '5px', backgroundColor: '#7367f0', width: '5px', opacity: 0.75 }" 
          :bar-style="{ right: '2px', borderRadius: '9px', backgroundColor: '#7367f0', width: '9px', opacity: 0.2 }">
          
          <div class="d-flex flex-column gap-3 p-2">
            <BTable ref="refPurchaseListTable" class="position-relative rounded-lg shadow-sm mb-0" responsive show-empty hover 
              primary-key="_id" :items="purchaseData.items" foot-clone :tbody-tr-class="(item, type)=>item.isJournaled?'bg-light-secondary':''"
              :fields="[
                {key:'name',label:capitalize($t('form.name'))}, 
                {key:'dr',label:capitalize($t('finance.dr'))},
                {key:'cr',label:capitalize($t('finance.cr'))},
              ]">
              <!-- Name -->
              <template #cell(name)="{ item }">
                <div class="d-flex gap-1 align-items-center">
                  <!-- Inventory -->
                  <q-chip v-if="item.isInventory" dense color="light-primary">
                    <q-avatar v-if="item.isInventory" color="primary" text-color="white">
                      <fa-icon icon="box" />
                    </q-avatar>
                    <span class="ml-25">{{item.name | capitalize}}</span>
                  </q-chip>
                  <!-- Payment -->
                  <q-chip v-else-if="item.isPayment" dense :color="item.paid?'light-success':'light-warning'">
                    <q-avatar :color="item.paid?'success':'warning'" text-color="white">
                      <fa-icon :icon="['far',item.paid?'check':'circle']" />
                    </q-avatar>
                    <span class="ml-25">{{item.paid?$t('supply-chain.purchase.paid'):$t('supply-chain.purchase.crediting')}}</span>
                  </q-chip>
                  <!-- Expense -->
                  <q-chip v-else dense color="light-danger">
                    <q-avatar color="danger" text-color="white">
                      <fa-icon icon="coins" />
                    </q-avatar>
                    <span class="ml-25">{{item.name | capitalize}}</span>
                  </q-chip>
                  
                  <!-- Journal status -->
                  <q-chip dense v-if="item.isJournaled" color="light-success">
                    <fa-icon :icon="['far','sack-dollar']" />
                  </q-chip>

                  <span v-if="item.quantity!==1">x {{item.quantity}}</span>
                </div>
              </template>

              <!-- DR -->
              <template #cell(dr)="{ item }">
                <div v-if="item.drcr==='dr'" class="text-nowrap" :class="item.amount<0?'text-danger':'text-primary'">
                  {{item.amount<0?'-':''}} {{item.currency}} {{Math.abs(item.amount||0) | price}}
                </div>
              </template>

              <!-- CR -->
              <template #cell(cr)="{ item }">
                <div v-if="item.drcr==='cr'" class="text-nowrap" :class="item.amount<0?'text-danger':'text-primary'">
                  {{item.amount<0?'-':''}} {{item.currency}} {{Math.abs(item.amount||0) | price}}
                </div>
              </template>

              <template #foot()="data">
                <span v-if="data.label.toLowerCase()==='dr'"><i>{{total.dr | price}}</i></span>
                <span v-if="data.label.toLowerCase()==='cr'"><i>{{total.cr | price}}</i></span>
              </template>
            </BTable>
            <PaymentAddSection :purchasePayments.sync="payments" :isReturn="purchaseData.isReturn" />
          </div>
        </q-scroll-area>

        <div class="shadow-top bg-light-secondary p-2">
          <div class="d-flex justify-content-between">
            <q-btn push no-caps color="primary" class="mr-2" type="submit" :loading="loading" :label="capitalize($t('action.save'))"/>
            <q-btn push no-caps color="danger" v-close-popup :label="capitalize($t('action.cancel'))"/>
          </div>
        </div>
      </q-form>
    </div>
  </q-dialog>
</template>

<script>
import store from '@/store'
import { ref } from '@vue/composition-api'
import usePurchase from '../usePurchase'
import { price, capitalize } from '@core/utils/filter'
import { Toast, Dialog } from '@core/utils/other'
import { BTable } from 'bootstrap-vue'
import PaymentAddSection from './PaymentAddSection.vue'

export default {
  name: "pay-dialog",
  components: {
    BTable,
    PaymentAddSection
  },
  filters: {
    capitalize, price
  },
  setup(_,{emit}){
    const {
      loading,
      total,
      balanceDifference,

      // purchase
      purchaseData,
      fetchPurchase
    } = usePurchase()


    // Dialog
    const isDialogOpen = ref(false)
    let cancelAction = () => isDialogOpen.value = false
    const cancel = ()=>cancelAction()
    const open = purchaseId=>new Promise(res => {
      loading.value = false
      fetchPurchase(purchaseId, 
      p => {
        loading.value = false
        purchaseData.value = p
      },
      e => {
        loading.value = false
        Toast.fire({icon:"error", title:e.message})
        res(false)
      })
    })



    const payments = ref([])

    const payPurchase = ()=>{
      // 验证 payments
      if (!purchaseData.value._id) return Toast.fire({icon: 'error', title: tt.incompleteOrder})

      // 验证 Entry balance
      if(!balanceDifference() < 0.01) return Toast.fire({icon: 'error', title: tt.notBalance})

      // 提交
      loading.value = true
      store.dispatch('purchase/payPurchase', {id:purchaseData.value._id, payments:payments.value}).then(() => {
        loading.value = false
        Toast.fire({icon: 'success', title:tt.success})
        emit('on-success')
        close()
      }).catch(e =>{
        loading.value = false
        Dialog(false).fire({ icon: 'error', title: e.message })
      })
    }

    return {
      loading,
      capitalize,
      total,
      
      
      // Dialog
      isDialogOpen,
      open,
      cancel,

      purchaseData,

      //Payment
      payments,

      payPurchase      
    }
  }

}
</script>

<style lang="scss">
#pay-dialog {
  [class*="q-"].row {
    flex-wrap: unset;
  }
}
</style>