<template>
  <component :is="isDialog?'q-dialog':'div'" id="purchase-list" v-model="isDialogOpen" @hide="cancel">
    <div :class="{'dialog-view':isDialog}">
      <ListTable ref="tableRef" id="purchase-table" slim :tabOptions="tabOptions" :columns="columns" :fetchAsync="fetchPurchases" dataName="purchases" :selectable="selectable" search="orderNo,trackNo,details,tags,amount,items.name">
        <!-- Add button -->
        <template #add>
          <q-btn v-if="$can('create','Purchase')" push no-caps color="primary" @click.stop="purchaseEditRef.open().then(v=>v?tableRef.refetchTable():'')">
            <span class="text-nowrap text-capitalize">{{$t('action.add')}}</span>
          </q-btn>
        </template>

        <!-- Index -->
        <template #cell(index)="{ item }"><span class="text-primary">{{item.index}}</span></template>

        <!-- Date -->
        <template #cell(orderedAt)="{ item }">
          <div class="text-left">{{item.orderedAt | date}}</div>
        </template>

        <!-- Order number -->
        <template #cell(orderNo)="{ item }">
          <q-btn flat padding="0.25rem 0.50rem" @click.stop="purchaseViewRef.open(item._id).then(v=>v?tableRef.refetchTable():'')" class="w-100 rounded-lg">
            <div class="flex-grow-1 d-flex justify-content-between align-items-center">
              <div class="flex gap-2 align-items-center flex-nowrap">
                <Avatar :url="item.files"/>
                <div class="text-left">
                  <div class="text-nowrap" :class="item.deleted?'text-danger':'text-primary'">{{ item.orderNo }}</div>
                  <q-chip v-if="item.approvalStatus" dense :color="`light-${$t('supply-chain.status.'+item.approvalStatus+'.color')}`">
                    <fa-icon fixed-width :icon="['far',$t('supply-chain.status.'+item.approvalStatus+'.icon')]" />
                  </q-chip>
                </div>
              </div>
              <fa-icon :icon="['far','chevron-right']" class="text-primary ml-1"/>
            </div>
          </q-btn>
        </template>

        <!-- Details -->
        <template #cell(details)="{ item }">
          <div class="text-left">
            <!-- Tracking number -->
            <q-chip v-if="item.trackNo" dense>
              <q-avatar color="primary" text-color="white">
                <fa-icon icon="shipping-fast" />
              </q-avatar>
              <span class="ml-25">{{item.trackNo}}</span>
            </q-chip>
            <!-- detials -->
            <small class="d-block my-25" v-if="item.details" style="white-space: break-spaces">{{item.details}}</small>
          </div>
        </template>

        <!-- supplier -->
        <template #cell(supplier.corpName)="{ item }">
          <div v-if="item.supplier" class="text-left">
            <div>{{item.supplier.corpName}}</div>
            <small>{{item.supplier.name}} {{item.supplier.phone}}</small>
          </div>
        </template>

        <!-- Amount -->
        <template #cell(total)="{ item }">
          <div class="text-nowrap text-left" :class="item.total<0?'text-danger':'text-primary'">
            {{item.total||0 | price($t(`currency.${item.currency}.sign`))}}
          </div>
        </template>

        <!-- Items -->
        <template #cell(variants.name)="{ item }">
          <div v-for="variant in item.variants.filter(t=>!t.isPayment)" :key="item._id" class="d-flex gap-1 justify-content-between">
            <div class="d-flex gap-1 align-items-center flex-nowarp">
              <q-chip dense :color="variant.isInventory?'light-primary':'light-danger'" class="flex-nowrap" :class="{'pl-0':variant.isInventory}">
                <q-chip dense v-if="variant.isInventory" :color="item.isFulfilled?'success':'warning'" class="justify-content-center" text-color="white">
                  <span>{{variant.fulfilledQuantity||0}}/{{item.quantity}}</span>
                  <!-- <fa-icon :icon="['fas',item.isFulfilled?'check':'shipping-fast']" /> -->
                </q-chip>
                <q-avatar v-else color="danger" text-color="white">
                  <fa-icon :icon="['fas','coins']" />
                </q-avatar>
                <span class="ml-25">{{variant.names | name}}</span>
              </q-chip>
            </div>
            <div class="text-nowrap text-right d-flex align-items-center" :class="variant.amount<0?'text-danger':'text-primary'">
              {{variant.amount | price($t(`currency.${variant.currency}.sign`))}} 
            </div>
          </div>
        </template>

        <!-- Tags -->
        <template #cell(tags)="{ item }">
          <div class="d-flex flex-wrap">
            <q-chip v-for="(tag, index) in item.tags" size="sm" color="warning" text-color="white" :key="tag.index" class="mx-25">{{tag}}</q-chip>
          </div>
        </template>

        <!-- Actions -->
        <template #cell(actions)="{ item }">
          <div v-if="!item.deleted" class="d-flex flex-nowrap gap-1 align-items-center justify-content-between action-btn">
            <!-- Approve -->
            <q-btn padding="0.75rem 1rem" v-if="$can('approve','Purchase') && !item.isApproved" push :color="item.isApproved===false?'danger':'warning'" @click.stop="approvePurchase(item._id).then(v=>v?tableRef.refetchTable():'')">
              <feather-icon :icon="item.isApproved===false?'UserXIcon':'UserCheckIcon'"/>
              <q-tooltip class="bg-red" anchor="top middle" self="bottom middle">{{capitalize($t('action.approve'))}}</q-tooltip>
            </q-btn>
            <!-- Pay -->
            <q-btn v-else-if="$can('create','Finance') && item.isApproved && !item.isPaid" push color="warning" padding="0.75rem 1rem" class="text-center" @click.stop="payRef.open(item._id)">
              <fa-icon :icon="['far','dollar-sign']"/>
              <q-tooltip class="bg-red" anchor="top middle" self="bottom middle">{{capitalize($t('action.pay'))}}</q-tooltip>
            </q-btn>
            <!-- Check in -->
            <q-btn v-else-if="$can('create','Inventory') && item.approvalStatus==='journaled'" push color="success" @click.stop="checkInOrder(item._id)">
              <fa-icon :icon="['far','check']" class="mr-50"/>{{capitalize($t('form.check_in'))}}
            </q-btn>
            <q-btn v-if="$can('update','Purchase') && item.approvalStatus!=='fulfilled'" flat round padding="0.75rem" @click.stop="purchaseEditRef.open(item._id).then(v=>v?tableRef.refetchTable():'')">
              <feather-icon icon="EditIcon" class="text-primary cursor-pointer"/>
            </q-btn>
            <q-btn v-if="$can('delete','Purchase') && !item.deleted && !item.isApproved" flat round padding="0.75rem" @click="deletePurchase(item._id).then(v=>v?tableRef.refetchTable():'')">
              <feather-icon icon="TrashIcon" class="text-danger cursor-pointer"/>
            </q-btn>
          </div>
        </template>
      </ListTable>
    </div>

    <PurchaseView ref="purchaseViewRef"/>
    <PurchaseEdit ref="purchaseEditRef"/>
    <SupplierView ref="supplierViewRef"/>
    <PayDialog ref="payRef"/>

  </component>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { price, date, time, capitalize, mmdd, i18n, name } from '@core/utils/filter'
import store from '@/store'
import usePurchase from './usePurchase'
import FileGallery from '@core/components/file-gallery/FileGallery'
import Avatar from '@core/components/Avatar'
import ListTable from '@core/components/ListTable'
import PurchaseEdit from './PurchaseEdit'
import PurchaseView from './PurchaseView'
import SupplierView from '../../member/supplier/SupplierView'
import PayDialog from './components/PayDialog'

export default {
  name: 'purchase-list',
  components: {
    FileGallery, Avatar, ListTable,
    PurchaseView,
    PurchaseEdit,
    SupplierView,
    PayDialog,
  },
  props:{
    isDialog: Boolean,
    showIcon: Boolean,
    selectable: Boolean,
    filters: {
      type: Object,
      default: ()=>{}
    },
    sort: String
  },
  filters: {
    price, capitalize, date, time, mmdd, i18n, name
  },
  setup() {
    // Ref
    const purchaseViewRef = ref(null)
    const purchaseEditRef = ref(null)
    const supplierViewRef = ref(null)
    const payRef = ref(null)

    const { 
      approvePurchase,
      deletePurchase 
    } = usePurchase()

    // Table
    const tableRef = ref(null)
    const columns = ref([
      { key: 'purchaseSeq', label: '#', noFilter: true, sortable: true},
      { key: 'orderNo', label:i18n('form.order_number'), sortable: true },
      { key: 'description', label:i18n('form.description')},
      { key: 'total', label:i18n('form.total'), isNumber:true, sortable: true},
      { key: 'variants.names', label:i18n('form.variants')},
      { key: 'supplier.corpName', label:(i18n('member.supplier._'))},
      { key: 'tags', label:i18n('form.tags')},
      { key: 'orderedAt', label:i18n('form.date'), isDateRange: true, sortable: true },
      { key: 'actions', label:i18n('table.action'), noFilter: true }
    ])

    // Tabs
    const tabOptions = ref([{key:'all', value: ''}].concat(['new','reviewing','payment','shipping','received'].map(s=>({key:s, value: {status:s}}))))

    // Action
    const fetchPurchases = params => store.dispatch('purchase/fetchPurchases', params)

    // Dialog view
    const isDialogOpen = ref(false)
    
    let cancelAction = () => isDialogOpen.value = false
    const cancel = ()=>cancelAction()
    const selectOne = ()=>new Promise(res => {
      // 隐藏column
      columns.value = columns.value.filter(c=>c.key!=='actions')
      isDialogOpen.value = true
      cancelAction = ()=>{
        isDialogOpen.value = false
        res(false)
      }
      watch(tableRef, ref=>ref?.clickRow().then(val=>{
        isDialogOpen.value = false
        res(val)
      }))
    })

    return {
      capitalize,
      // Dialog View
      isDialogOpen,
      cancel,
      selectOne,
      
      // Table
      columns,
      tableRef,

      // Action
      fetchPurchases,
      approvePurchase,
      deletePurchase,

      tabOptions,

      // Refs
      purchaseViewRef,
      purchaseEditRef,
      supplierViewRef,
      payRef
    }
  }
}
</script>
<style lang="scss" scope>
#purchase-table {
  .dialog-view {
    max-width: 900px;
    width:100%;
  }
  td:first-child {
    max-width: 120px
  }
}
</style>